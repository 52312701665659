import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./config/queryClient";
import { Route, Routes } from "react-router";
import AddReport from "./components/pages/AddReport";
import AppContainer from "./components/AppContainer";
import Pitchers from "./components/pages/Pitchers";
import SinglePitcher from "./components/pages/SinglePitcher";
import Report from "./components/pages/Report";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<AppContainer />}>
            <Route index element={<AddReport />} />
            <Route path="pitchers" element={<Pitchers />} />
            <Route path="pitchers/:id" element={<SinglePitcher />} />
            <Route path="reports/:id" element={<Report />} />
          </Route>
        </Routes>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
