import { useToast } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { addReport } from "../api";

const useAddReport = ({ setReport, dateRef }) => {
  const toast = useToast();

  return useMutation(async (data) => addReport(data), {
    onSuccess: async () => {
      toast({
        title: "Successfully Added Report",
        status: "success",
        duration: 2500,
        position: "bottom-left",
      });
      setReport({
        catcherId: "",
        pitcherId: "",
        FB: "",
        CB: "",
        SLI: "",
        CU: "",
        other: "",
      });
      dateRef.current.value = null;
    },
    onError: async () => {
      toast({
        title: `Failed to Add Report`,
        status: "error",
        duration: 2500,
        position: "bottom-left",
      });
    },
  });
};

export default useAddReport;
