import { Box, Flex, Text } from "@chakra-ui/react";
import usePlayers from "../../hooks/usePlayers";
import LoadingSpinner from "../basic/LoadingSpinner";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";

const Pitchers = () => {
  const navigate = useNavigate();
  const { data = {}, isLoading, isError } = usePlayers();
  const { pitchers = [] } = data;

  return (
    <Box margin="0 auto" textAlign="left" maxWidth="400px">
      {isLoading ? (
        <LoadingSpinner m="0 50%" />
      ) : isError ? (
        <Text color="brand.red">Failed to load pitchers</Text>
      ) : (
        pitchers.map((pitcher) => (
          <Flex
            justify="space-between"
            align="center"
            key={pitcher.playerId}
            p=".5em"
            cursor="pointer"
            borderRadius="5px"
            _hover={{ bgColor: "brand.hoverBgDark" }}
            onClick={() => navigate(`/pitchers/${pitcher.playerId}`)}
          >
            <Text>
              {`${pitcher.firstName} ${pitcher.lastName}`}
              <Text as="span" ml="1rem" color="brand.bodyText">
                #{pitcher.number}
              </Text>
            </Text>
            <ExternalLinkIcon color="brand.orange" />
          </Flex>
        ))
      )}
    </Box>
  );
};
export default Pitchers;
