import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import Navbar from "./Navbar";

const AppContainer = () => (
  <Container textAlign="center" fontSize="2xl" p="3rem 1rem" maxW="1350px">
    <Navbar />
    <Outlet />
  </Container>
);

export default AppContainer;
