import axiosClient from "../config/axiosClient";

export const getPlayers = async () =>
  axiosClient
    .get("/players")
    .then(({ data }) => data)
    .catch(({ response: { status, data } }) =>
      Promise.reject({ status, ...data })
    );

export const getPitcherReports = async (pitcherId) =>
  axiosClient
    .get(`/pitchers/${pitcherId}/reports`)
    .then(({ data }) => data)
    .catch(({ response: { status, data } }) =>
      Promise.reject({ status, ...data })
    );

export const getReportById = async (reportId) =>
  axiosClient
    .get(`/reports/${reportId}`)
    .then(({ data }) => data)
    .catch(({ response: { status, data } }) =>
      Promise.reject({ status, ...data })
    );

export const addReport = async (data) =>
  axiosClient
    .post("/reports", data)
    .then(({ data }) => data)
    .catch(({ response: { status, data } }) =>
      Promise.reject({ status, ...data })
    );
