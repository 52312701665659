import { useQuery } from 'react-query';
import { getPlayers } from '../api';
import { PLAYERS } from '../constants/queryKeys';

const usePlayers = () =>
  useQuery(PLAYERS, getPlayers, {
    staleTime: Infinity,
  });

export default usePlayers;
