import { Box, Heading, Text } from "@chakra-ui/react";
import LoadingSpinner from "../basic/LoadingSpinner";
import { useLocation, useNavigate, useParams } from "react-router";
import { formatDate } from "../../utils";
import { ArrowBackIcon } from "@chakra-ui/icons";
import useReport from "../../hooks/useReport";

const Report = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = location.state?.goBack;
  const { data = {}, isLoading, isError } = useReport(id);
  const {
    firstName,
    lastName,
    catcherFirstName,
    catcherLastName,
    date,
    FB,
    CB,
    SLI,
    CU,
    other,
  } = data;

  return (
    <Box
      margin="0 auto"
      textAlign="left"
      maxWidth="800px"
      p="1em"
      border="2px solid"
      borderColor="brand.bodyText"
      borderRadius={5}
    >
      {isLoading ? (
        <LoadingSpinner m="0 50%" />
      ) : isError ? (
        <Text color="brand.red">Failed to load report</Text>
      ) : (
        <Box
          __css={{
            h3: { color: "brand.orange", fontWeight: "bold" },
            ".text": {
              mb: "1rem",
              color: "brand.bodyText",
            },
          }}
        >
          <ArrowBackIcon
            mb=".6em"
            boxSize="1.2em"
            cursor="pointer"
            _hover={{ color: "brand.blue" }}
            onClick={() => navigate(goBack ? -1 : "/pitchers")}
          />
          <Heading as="h1" color="brand.headingText">
            {`${firstName} ${lastName}`}
          </Heading>
          <Text color="brand.bodyText" m=".5em 0" fontSize="3xl">
            {formatDate(new Date(date))}
          </Text>
          <Text color="brand.bodyText" m=".5em 0" fontSize="2xl">
            (by {`${catcherFirstName} ${catcherLastName}`})
          </Text>

          <Text as="h3">Fastball</Text>
          <Text className="text">{FB}</Text>
          <Text as="h3">Curveball</Text>
          <Text className="text">{CB}</Text>
          <Text as="h3">Slider</Text>
          <Text className="text">{SLI}</Text>
          <Text as="h3">Change Up</Text>
          <Text className="text">{CU}</Text>
          <Text as="h3">Other</Text>
          <Text className="text">{other}</Text>
        </Box>
      )}
    </Box>
  );
};
export default Report;
