import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import LoadingSpinner from "../basic/LoadingSpinner";
import { useNavigate, useParams } from "react-router";
import usePitcherReports from "../../hooks/usePitcherReports";
import { formatDate } from "../../utils";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";

const Subheading = ({ text, ...props }) => (
  <Heading
    as="h2"
    fontSize="2xl"
    color="brand.orange"
    flex={1}
    textAlign="left"
    {...props}
  >
    {text}
  </Heading>
);

const AccordionPanelItem = ({ report, navigate }) => (
  <Box fontSize="lg" mb=".5em">
    <Text color="brand.bodyText">
      {formatDate(new Date(report.date))}
      <ExternalLinkIcon
        ml=".5em"
        color="brand.blue"
        cursor="pointer"
        onClick={() =>
          navigate(`/reports/${report.id}`, {
            state: { goBack: true }, // used for logic the back arrow
          })
        }
      />
    </Text>
    <Text>{report.text}</Text>
  </Box>
);

const SinglePitcher = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data = {}, isLoading, isError } = usePitcherReports(id);
  const {
    firstName,
    lastName,
    number,
    fastball = [],
    curveball = [],
    slider = [],
    changeUp = [],
  } = data;

  return (
    <Box margin="0 auto" textAlign="left" maxWidth="800px">
      {isLoading ? (
        <LoadingSpinner m="0 50%" />
      ) : isError ? (
        <Text color="brand.red" textAlign="center">
          Failed to load reports
        </Text>
      ) : (
        <Box>
          <ArrowBackIcon
            mb=".6em"
            boxSize="1.2em"
            cursor="pointer"
            _hover={{ color: "brand.blue" }}
            onClick={() => navigate("/pitchers")}
          />
          <Heading as="h1" color="brand.headingText" mb="1em">
            {`${firstName} ${lastName}`}{" "}
            <Text as="span" ml=".25em" color="brand.bodyText">
              #{number}
            </Text>
          </Heading>

          <Accordion allowMultiple defaultIndex={[]}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Subheading text="Fastball" />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {fastball.map((report) => (
                  <AccordionPanelItem
                    report={report}
                    navigate={navigate}
                    key={report.id}
                  />
                ))}
                {fastball.length === 0 && (
                  <Text color="brand.bodyText" fontSize="lg">
                    No data found
                  </Text>
                )}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Subheading text="Curveball" />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {curveball.map((report) => (
                  <AccordionPanelItem
                    report={report}
                    navigate={navigate}
                    key={report.id}
                  />
                ))}
                {curveball.length === 0 && (
                  <Text color="brand.bodyText" fontSize="lg">
                    No data found
                  </Text>
                )}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Subheading text="Slider" />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {slider.map((report) => (
                  <AccordionPanelItem
                    report={report}
                    navigate={navigate}
                    key={report.id}
                  />
                ))}
                {slider.length === 0 && (
                  <Text color="brand.bodyText" fontSize="lg">
                    No data found
                  </Text>
                )}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Subheading text="Change Up" />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {changeUp.map((report) => (
                  <AccordionPanelItem
                    report={report}
                    navigate={navigate}
                    key={report.id}
                  />
                ))}
                {changeUp.length === 0 && (
                  <Text color="brand.bodyText" fontSize="lg">
                    No data found
                  </Text>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Text color="brand.bodyText" m=".75em 0 .25em" fontSize="xl">
            Recent Reports
          </Text>
          {fastball.map((report) => (
            <Text
              color="brand.bodyTextLight"
              fontSize="xl"
              mb=".25em"
              cursor="pointer"
              _hover={{ color: "brand.blue" }}
              onClick={() =>
                navigate(`/reports/${report.id}`, {
                  state: { goBack: true }, // used for logic the back arrow
                })
              }
              key={report.id}
            >
              {formatDate(new Date(report.date))}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};
export default SinglePitcher;
