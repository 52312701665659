import { useState, useRef } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import usePlayers from "../../hooks/usePlayers";
import LoadingSpinner from "../basic/LoadingSpinner";
import useAddReport from "../../hooks/useAddReport";

const AddReport = () => {
  const dateRef = useRef({});
  const [report, setReport] = useState({
    catcherId: "",
    pitcherId: "",
    FB: "",
    CB: "",
    SLI: "",
    CU: "",
    other: "",
  });
  const { catcherId, pitcherId, FB, CB, SLI, CU, other } = report;

  const { data = {}, isLoading, isSuccess, isError } = usePlayers();
  const { pitchers, catchers } = data;

  const initHandler = (property) => (e) =>
    setReport((prev) => ({
      ...prev,
      [property]: e.target.value,
    }));

  const { mutate: addReport, isLoading: sendingReport } = useAddReport({
    setReport,
    dateRef,
  });

  return (
    <Flex
      m="2rem auto"
      w="100%"
      maxW="500px"
      direction="column"
      justify="center"
      align="center"
    >
      <FormControl isRequired mt="1.5rem">
        <FormLabel>Catcher</FormLabel>
        {isLoading && <LoadingSpinner display="block" m=".5rem 0" />}
        {isError && (
          <Text textAlign="left" m=".75rem 0" fontSize="md" color="brand.red">
            Failed to load catchers
          </Text>
        )}
        {isSuccess && catchers && (
          <Select
            value={catcherId}
            onChange={initHandler("catcherId")}
            placeholder="---"
          >
            {catchers.map((player) => (
              <option value={player.playerId}>
                {player.firstName + " " + player.lastName}
              </option>
            ))}
          </Select>
        )}
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Pitcher</FormLabel>
        {isLoading && <LoadingSpinner display="block" m=".5rem 0" />}
        {isError && (
          <Text textAlign="left" m=".75rem 0" fontSize="md" color="brand.red">
            Failed to load pitchers
          </Text>
        )}
        {isSuccess && pitchers && (
          <Select
            value={pitcherId}
            onChange={initHandler("pitcherId")}
            placeholder="---"
          >
            {pitchers.map((player) => (
              <option value={player.playerId}>
                {player.firstName + " " + player.lastName}
              </option>
            ))}
          </Select>
        )}
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Date</FormLabel>
        <Input ref={dateRef} placeholder="Select Date" size="lg" type="date" />
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Fastball</FormLabel>
        <Textarea
          size="md"
          type="text"
          value={FB}
          onChange={initHandler("FB")}
        />
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Curveball</FormLabel>
        <Textarea
          size="md"
          type="text"
          value={CB}
          onChange={initHandler("CB")}
        />
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Slider</FormLabel>
        <Textarea
          size="md"
          type="text"
          value={SLI}
          onChange={initHandler("SLI")}
        />
      </FormControl>

      <FormControl isRequired mt="1.5rem">
        <FormLabel>Change Up</FormLabel>
        <Textarea
          size="md"
          type="text"
          value={CU}
          onChange={initHandler("CU")}
        />
      </FormControl>

      <FormControl mt="1.5rem">
        <FormLabel>Other</FormLabel>
        <Textarea
          placeholder="Any additional info"
          size="md"
          type="text"
          value={other}
          onChange={initHandler("other")}
        />
      </FormControl>

      <Button
        disabled={
          isError || !catcherId || !pitcherId || !FB || !CB || !SLI || !CU
        }
        isLoading={sendingReport}
        variant="solid"
        color="brand.green"
        size="md"
        mt="1.25rem"
        onClick={() => addReport({ ...report, date: dateRef.current.value })}
      >
        Submit
      </Button>
    </Flex>
  );
};
export default AddReport;
